
export default [

  {
    name: '首頁',
      to: '/admin/home',
    icon: 'cil-pencil',
    powerid: 2
  },
  {
    name: '發電資料查詢',
      to: '/admin/power',
    icon: 'cil-puzzle',
    powerid: 101,
    child: [
      {

        name: '基本查詢',
            to: '/admin/power/day',
        powerid: 10101
      },
      {
        name: '發電效率查詢',
          to: '/admin/power/effect',
        powerid: 10102
      },
      {
        name: '依狀態查詢',
          to: '/admin/power/status',
        powerid: 10103
      }
    ],
  },
  {
    name: '客戶資料管理',
      to: '/admin/main/index',
    icon: 'cil-cursor',
    powerid: 102,
    child: [
      {

        name: '客戶資料維護',
            to: '/admin/main/index',
        powerid: 10201
      },
      {

        name: '設置者資料庫',
            to: '/admin/Customer/index',
        powerid: 10202
      }, 
      {
        name: '新增設備',
        to: '/admin/SingleInput/list',
        powerid: 10203
      }
    ],
  },
  {
    name: '資料列印',
      to: '/admin/DocTemplate/index',
    icon: 'cil-cursor',
    powerid: 107,
    child: [
      {

        name: '列印文件維護',
            to: '/admin/Documents/index',
        powerid: 10701
      },
      {

        name: '列印文件模板',
            to: '/admin/DocTemplate/index',
        powerid: 10702
      },
    ],
  },
  {

    name: '太陽能面板組管理',
      to: '/admin/board/index',
    icon: 'cil-notes',
    powerid: 103,
    child: [
      {

        name: '太陽能面板組',
            to: '/admin/board/index',
        powerid: 10301
      },
      {

        name: '單太陽能板(各廠牌)',
          to: '/admin/panel/index',
        powerid: 10302
      },

    ],
  },
  {

    name: '逆變器管理',
      to: '/admin/inverter/index',
    icon: 'cil-star',
    powerid: 104,
    child: [
      {

        name: '逆變器設置',
            to: '/admin/inverter/index',
        powerid: 10401,
        badge: {
          color: 'info',
          text: 'NEW',
        },
      },
      {

        name: '驅動程式代碼',
          to: '/admin/Drivers/index',
        powerid: 10402
      },
      {

        name: '汰除逆變器管理',
          to: '/admin/InverterBroken/index',
        powerid: 10403
      },
    ],
  },
  {

    name: '監控盒管理',
      to: '/admin/notifications/index',
    icon: 'cil-bell',
    powerid: 105,
    child: [
      {

        name: '監控盒清單',
            to: '/admin/dtu/index',
        powerid: 10501
      },
    ],
  },
  {
    name: 'SIM卡管理',
      to: '/admin/sim',
    icon: 'cil-calculator',
    powerid: 106,
    child: [
      {
        name: 'SIM卡資訊',
            to: '/admin/sim/index',
        powerid: 10601
      },
    ]
  },
  {
    name: '電費收入/租金計算',
      to: '/admin/Bill',
    icon: 'cil-calculator',
    powerid: 106,
    child: [
      {
        name: '電費收入',
            to: '/admin/bill/index',
        powerid: 10601
      },
    ]
  },
  {

    name: '系統管理',
      to: '/admin/user',
    icon: 'cil-star',
    powerid: 200,
    child: [
      {

        name: '系統帳號管理',
            to: '/admin/Admin/index',
        powerid: 20001
      },
      {          
        name: '系統參數維護',
          to: '/admin/setting/index',
        powerid: 20002
      },
      {

        name: '選單維護',
          to: '/admin/menuset/index',
        powerid: 20003
      },
      {

        name: '選單分類',
          to: '/admin/module/index',
        powerid: 20004
      },
    ],
  }
]
