<template>
	<layout-div>
		<dic class="card">
			<div class="card-body">
				<div class="form-horizontal col-md-8">
					<div class="form-group row">
						<label htmlFor="housecode" class="col-sm-2 col-form-label">案場代號</label>
						<div class="col-sm-10">
							<input v-model="housecode" type="text" class="form-control" id="housecode" name="housecode"
								readonly />
						</div>
					</div>
					<div class="form-group row">
						<label htmlFor="customer" class="col-sm-2 col-form-label">客戶</label>
						<div class="col-sm-10">
							<input v-model="customer" type="text" class="form-control" id="customer" name="customer"
								readonly />
						</div>
					</div>
				</div>
			</div>
		</dic>
		<div class="card">
			<div class="card-header">
				<div class="btn-group">
					<button @click="showAppendForm()" class="btn btn-secondary">新增文件</button>
					<!-- <router-link :to="`/admin/Document/create/${code}`" class="btn btn-primary">新增</router-link> -->
					<router-link :to="`/admin/Main/index`" class="btn btn-outline-secondary mx-1">返回列表</router-link>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th v-for="header in headers" :key="header.id">
										<a href="#" @click="sort(header.id);">
											{{ header.label }}
											<HeaderIcon :caseby=header.icon />
										</a>
									</th>
									<th width="240px">管理</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in model" :key="item.id">
									<td>{{ item.id }}</td>
									<td><a href="#" @click="download(item.editKey, item.filename)"
											class="link-primary">{{ item.filename }}</a></td>
									<td>{{ item.printCount }}</td>
									<td>{{ item.update_time }}</td>

									<td>
										<button @click="showEditForm(item.editKey)"
											className="btn btn-outline-success mx-1">
											編輯
										</button>
										<button @click="handleDelete(item.delKey)"
											className="btn btn-outline-danger mx-1">
											刪除
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</layout-div>

	<div class="modal fade" id="editModal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-body">
					<div class="card">

						<div class="card-body col">

							<div class="form-group row">
								<div class="input-group">
									<input type="file" name="file" id="doc" />
								</div>
							</div>

							<div class="form-group row">
								<label class="control-label col-sm-2">預設列印份數</label>
								<input type="number" v-model="editVM.printCount" id="printCount"
									class="form-control col-sm-1" />
							</div>

						</div>

					</div>
				</div>
				<div class="modal-footer justify-content-between">
					<button type="button" @click="Upload()" class="btn btn-primary">儲存上傳</button>
					<button type="button" class="btn btn-default" data-dismiss="modal">關閉</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>

</template>

<script>
import $ from 'jquery'
import baseList from '@/views/baseList.vue'
import { listDocument, addDocument, deleteDocument, download, getDocument, postDocument } from '@/api/Document.js'
import Swal from 'sweetalert2'
import { saveAs } from 'file-saver';

export default {
	name: 'Documentlist',
	extends: baseList,
	data() {
		return {
			headers: [
				{ id: 'id', label: "編號", icon: "▼" },
				{ id: 'filename', label: "檔案名稱", icon: "" },
				{ id: 'printCount', label: "預設列印份數", icon: "" },
				{ id: 'update_time', label: "最後更改日期", icon: "" },

			],
			editype: 0,
			code: "",
			housecode: "",
			customer: "",
			model: [],
			editVM: {
				id: '',
				housecode: '',
				printCount: 1,
				filename: '',
				file: ''
			},
		};
	},
	methods: {

		showAppendForm() {
			this.reset();
			this.editype = 0;
			$("#editModal").modal('show');
		},
		showEditForm(id) {
			this.reset();
			this.editVM.id = id;
			this.editype = 1;

			getDocument(id).then(rep => {
				let sc = rep.data;
				this.editVM.printCount = sc.printCount;
				$("#editModal").modal('show');

			});

		},
		reset() {
			$("#doc").val('');
			this.editVM.file = '';
			this.editVM.printCount = 1;
			this.editVM.housecode = this.housecode;
		},
		fetchList() {

			this.code = this.$route.params.housecode;
			listDocument(this.code).then(rep => {

				let sc = rep.data;

				this.housecode = sc.housecode;
				this.customer = sc.customer;
				this.model = sc.items;
				return rep;
			});
		},
		deleteRecord(id) {
			return deleteDocument(id);
		},
		Upload() {

			this.editVM.file = document.querySelector('#doc').files[0];
			this.editVM.filename = $('#doc').val();

			if (this.editype == 0) {

				if (this.editVM.filename == '') {
					Swal.fire({
						icon: 'warning',
						title: '檔案不可以空白',
						showConfirmButton: true,
						timer: 1500
					});
					return;
				}
				else {

					addDocument(this.editVM).then(rep => {
						$("#editModal").modal('hide');
						this.fetchList();
						return rep;
					})
				}
			}
			else if (this.editype == 1) {

				postDocument(this.editVM).then(rep => {
					$("#editModal").modal('hide');
					this.fetchList();
					return rep;
				})
			}

		},
		download(code, filename) {

			download(code).then(rep => {
				saveAs(rep, filename);

			})
		}
	},
};
</script>
