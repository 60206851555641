import { apiPost } from './service'

export function listPowerDayend(data) {
	return apiPost("/api/PowerDayend/day", data);
}

export async function listPowerWeekend(data) {
	return await apiPost("/api/PowerDayend/week", data);
}


export async function listPowerMonthend(data) {
	return await apiPost("/api/PowerDayend/month", data);
}

export async function listPowerData(data) {
	return await apiPost("/api/Power/list", data);
}

export async function listPowerCheckData(data) {
	return await apiPost("/api/Power/listPowerCheck", data);
}