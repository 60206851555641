<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()" :disabled="isSaving" type="button" class="btn btn-info mt-3">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right" to="/admin/DocTemplate/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="code" class="col-sm-2 col-form-label">代碼</label>
							<div class="col-sm-10">
								<input v-model="model.code" type="text" class="form-control" id="code" name="code" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="type" class="col-sm-2 col-form-label">分類</label>
							<div class="col-sm-10">
								<input v-model="model.type" type="text" class="form-control" id="type" name="type" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="title" class="col-sm-2 col-form-label">模板說明</label>
							<div class="col-sm-10">
								<input v-model="model.title" type="text" class="form-control" id="title" name="title" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="realfile" class="col-sm-2 col-form-label">上傳檔案</label>
							<div class="col-sm-10">
								<input type="file" name="file" id="doc" class="form-control" />

							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="version" class="col-sm-2 col-form-label">版號</label>
							<div class="col-sm-10">
								<input v-model="model.version" type="text" class="form-control" id="version"
									name="version" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="enable" class="col-sm-2 col-form-label">啟用</label>
							<div class="col-sm-10">
								<input v-model="model.enable" type="checkbox" class="form-control" id="enable"
									name="enable" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="update_time" class="col-sm-2 col-form-label">最後更改日期</label>
							<div class="col-sm-10">
								<input v-model="model.update_time" type="date" class="form-control" id="update_time"
									name="update_time" />
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
import $ from 'jquery'
import LayoutDiv from '@/components/LayoutDiv.vue'
import { addDocTemplate } from '@/api/DocTemplate.js'
import toastr from 'toastr'
import Swal from 'sweetalert2'


export default {
	name: 'ProjectCreate',
	components: {
		LayoutDiv
	},
	data() {
		return {
			filename: '',
			model: {
				code: '',
				type: '',
				title: '',
				file: '',
				version: '',
				enable: true,
				update_time: '',

			},
			isSaving: false,
		};
	},
	methods: {
		checkFields() {
			let test = true;
			if (this.model.code == "") test = this.pickupWarning("模板代號欄位必填");
			if (this.model.type == "") test = this.pickupWarning("分類欄位必填");
			if (this.model.title == "") test = this.pickupWarning("標題欄位必填");
			if (this.model.version == "") test = this.pickupWarning("版號欄位必填");
			return test;
		},
		pickupWarning(message) {
			toastr.error(message);
			return false;
		},
		handleSave() {

			if (this.isSaving == true) return;

			this.model.file = document.querySelector('#doc').files[0];
			this.filename = $('#doc').val();

			if (this.filename == '') {
				Swal.fire({
					icon: 'warning',
					title: '檔案不可以空白',
					showConfirmButton: true,
					timer: 1500
				});
				return;
			}


			this.isSaving = true

			addDocTemplate(this.model).then(rep => {

				this.isSaving = false;

				if (rep.code == 200)
					this.reset();

				return rep;
			})
				.catch(error => {
					this.isSaving = false
					return error
				});
		},
		reset() {
			$("#doc").val('');
			this.model.code = "";
			this.model.type = "";
			this.model.title = "";
			this.model.file = "";
			this.model.version = "";
			this.model.enable = true;
			this.model.update_time = "";

		}
	}
};
</script>
