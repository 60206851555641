import { apiGet, apiPost } from './service'

export async function listInverter(data) {
	return await apiPost("/api/Inverter/list", data);
}

export async function fullListInverter(data) {
	return await apiPost("/api/Inverter/fulllist", data);
}

export function getInverter(data) {
	let url = "/api/Inverter/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postInverter(data) {
	return apiPost("/api/Inverter/edit", data);
}

export function addInverter(data) {
	return apiPost("/api/Inverter/add", data);
}

export function deleteInverter(data) {
	return apiGet("/api/Inverter/delete/" + data);
}

export function withBoard() {
	return apiGet("/api/Inverter/withboard");
}
